

import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import Competition from "@/_models/Competition";

@Component
export default class TableAdmin extends Vue {

  @Prop({ type: Array, default: () => [] }) data!: any[];
  @Prop({ type: Array, default: () => [] }) columns!: string[];

  public selectedElement: any;
  public dialogDelete = false;

  public search = '';
  public selected: any[] = [];

  openDeleteDialog(element: any) {
    this.selectedElement = element;
    this.dialogDelete = true;
  }

  @Emit()
  clickCreate(): void {return;}

  getValue(key: string, element: any): string {
    const k = key.split('.');
    if (k.length > 1) {
      return this.getValue(k[1], element[k[0]]);
    }
    return element[k[0]];
  }

  @Emit()
  clickDelete(): any {
    this.dialogDelete = false;
    return this.selectedElement;
  }

  @Emit()
  edit(element: any): any {
    return element;
  }

}
