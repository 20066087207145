
import { Component, Vue } from 'vue-property-decorator';
import SigninRequest from "@/_models/_requests/SigninRequest";
import {SignupRequest} from "@/_models/_requests/SignupRequest";
import {AuthService} from "@/_services/auth.service";
import {namespace} from "vuex-class";

const authModule = namespace('AuthModule');

@Component
export default class Login extends Vue {
  username = '';
  password = '';
  email = '';
  confirmPassword = '';
  alertActive = true;
  signupMode = false;
  hasVisiblePassword = false;

  @authModule.Action
  public signin!: (signinRequest: SigninRequest) => boolean;

  mounted(): void {
    this.adsenseAddLoad();
  }

  async signup(): Promise<void> {
    const request: SignupRequest = {
      username: this.username,
      password: this.password,
      email: this.email,
      role: []
    };
    const response = await AuthService.signup(request);
    if (!response) {
      this.$vs.notification({
        color: 'danger',
        position: 'top-right',
        title: 'Inscription impossible 😬',
        text: `L'adresse mail ou le nom d'utilisateur est déjà utilisé`
      });
    } else {
      this.signupMode = false;
    }
    this.email = '';
    this.username = '';
    this.password = '';
    this.confirmPassword = '';
  }

  get disabled(): boolean {
    return (this.signupMode && (this.password !== this.confirmPassword || this.email.length < 3)) || this.password.length < 6 || this.username.length < 3;
  }

  async callSignin(): Promise<void> {
    const signinRequest: SigninRequest = {
      username: this.username,
      password: this.password
    };
    const response = await this.signin(signinRequest);
    if (response) {
      this.$router.push('/home/' );
    } else {
      this.$vs.notification({
        color: 'danger',
        position: 'top-right',
        title: 'Connexion impossible 😬',
        text: `Les identifiants sont incorrects`
      });
    }
  }

  adsenseAddLoad(){
    let inlineScript   = document.createElement("script");
    inlineScript.type  = "text/javascript";
    inlineScript.text  = '(adsbygoogle = window.adsbygoogle || []).push({});'
    document.getElementsByTagName('body')[0].appendChild(inlineScript);
  }

}
