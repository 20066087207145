

import {Component, Prop, Vue} from "vue-property-decorator";
import ButtonTabProperties from "@/_models/ButtonTabProperties";

@Component
export default class ButtonTab extends Vue {

  @Prop({ type: Array, default: () => [] }) buttons!: ButtonTabProperties[];

}
