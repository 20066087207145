
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {AuthorizationService} from "@/_services/authorization.service";
import {PagePlaylists} from "@/_models/_playlist/PagePlaylists";
import {PlaylistService} from "@/_services/playlist.service";
import {namespace} from "vuex-class";
import JwtResponse from "@/_models/_response/JwtResponse";
import MobileMixin from "@/_mixins/MobileMixin";

const authModule = namespace('AuthModule');

@Component
export default class Sidebar extends MobileMixin {

  public active = 'house';

  @authModule.Getter
  public getCurrentUser!: JwtResponse;

  @authModule.Getter
  public isAdmin!: boolean;

  @authModule.Action
  public logout!: () => void;

  @Prop({ type: Boolean, default: true}) activeSidebar!: boolean;

}
