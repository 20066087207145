export default class JwtResponse {

    token = '';
    roles: string[] = [];
    username = '';
    email = '';
    id!: number;
    type = '';


}