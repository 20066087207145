

import {Component, Prop, Vue} from "vue-property-decorator";
import Match from "@/_models/Match";
import LogoInfo from "@/components/LogoInfo.vue";
import BetAlert from "@/components/BetAlert.vue";
import Bet, {BetStatus, ReactType} from "@/_models/Bet";
import BetService from "@/_services/bet.service";
import ButtonReact from "@/components/ButtonReact.vue";
import BadgeStatusBet from "@/components/BadgeStatusBet.vue";
import MobileMixin from "@/_mixins/MobileMixin";
import BetUtils from "@/_utils/bet.utils";
import Dayjs from "dayjs";
import dayjs from "dayjs";

@Component({
  components: {BadgeStatusBet, ButtonReact, BetAlert, LogoInfo}
})
export default class MatchDetailsDialog extends MobileMixin{

  public ReactType = ReactType;

  @Prop({ type: Object }) match!: Match;

  get getDisable(): boolean {
    const date = Dayjs(this.match.date);
    return dayjs().isAfter(date) || this.match.finished;
  }

  getColor(bet: Bet): string {
    return BetUtils.getColorByBet(bet.status);
  }

  async updateReact(bet: Bet, reactType: ReactType, addReact: boolean): Promise<void> {
    const betSaved: Bet = addReact ? await BetService.addReactForBet(this.match.id, bet.user.id, reactType) : await BetService.removeReactForBet(this.match.id, bet.user.id, reactType);
    switch (reactType) {
      case ReactType.FUNNY:
        bet.likedFunny = betSaved.likedFunny;
        bet.reactFunny = betSaved.reactFunny;
        break;
      case ReactType.WOW:
        bet.likedWow = betSaved.likedWow;
        bet.reactWow = betSaved.reactWow;
        break;
      case ReactType.LIKE:
        bet.likedGood = betSaved.likedGood;
        bet.reactGood = betSaved.reactGood;
        break;
    }
  }

}
