

import {Component, Vue} from "vue-property-decorator";
import RankService from "@/_services/rank.service";
import Rank from "@/_models/Rank";
import Podium from "@/components/Podium.vue";
import MobileMixin from "@/_mixins/MobileMixin";
@Component({
  components: {Podium}
})
export default class StandingCompetitionView extends MobileMixin {

  public standings: Rank[] = [];

  get getFirst(): Rank {
    return this.standings[0];
  }

  get getSecond(): Rank {
    return this.standings[1];
  }

  get getThird(): Rank {
    return this.standings[2];
  }

  async mounted(): Promise<void> {
    this.standings = await RankService.getStandingsByCompetition(this.$route.params.id);
  }

}
