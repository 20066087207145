

import {Component, Prop, Vue} from "vue-property-decorator";
import Bet, {BetStatus} from "@/_models/Bet";
import BetUtils from "@/_utils/bet.utils";

@Component
export default class Timeline extends Vue {

  @Prop({type: Array, default: () => []}) bets!: Bet[];

  getColorByBet(status: BetStatus): string {
    return BetUtils.getColorByBet(status);
  }

}
