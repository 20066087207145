

import {Component, Prop, Vue} from "vue-property-decorator";
import Match from "@/_models/Match";
import LogoInfo from "@/components/LogoInfo.vue";
import {namespace} from "vuex-class";
import MatchDetailsDialog from "@/components/MatchDetailsDialog.vue";
import BetAlert from "@/components/BetAlert.vue";

@Component({
  components: {BetAlert, MatchDetailsDialog, LogoInfo}
})
export default class CardMatchBet extends Vue {

  public dialogDetails = false;

  @Prop({ type: Object }) match!: Match;


}
