

import {Component, Vue, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import Competition from "@/_models/Competition";
import TableAdmin from "@/components/TableAdmin.vue";
import MatchCategory from "@/_models/MatchCategory";
import competition from "@/_store/competition";

const competitionModule = namespace('CompetitionModule');

@Component({
  components: {TableAdmin}
})
export default class AdminCompetitionsView extends Vue {

  public competitionRequest: Competition = new Competition();
  public dialogCreate = false;
  public competitionsLocals: Competition[] = [];
  public columns: string[] = ['id', 'name', 'image'];

  @competitionModule.Action('retrieveAll')
  public retrieveAll!: (route: string) => void;

  @competitionModule.Action
  public create!: (params: { element: Competition, route: string }) => Competition;

  @competitionModule.Getter('getElements')
  public getCompetitions!: Competition[];

  @competitionModule.Action
  public deleteById!: (params: { id: string, route: string }) => void;

  mounted(): void {
    this.retrieveAll('competition');
  }

  @Watch('getCompetitions')
  watchCompetitions(): void {
    this.competitionsLocals = this.getCompetitions;
  }

  public onCreateCompetition() {
    this.create({ element: this.competitionRequest, route: 'competition' });
    this.dialogCreate = false;
  }

  public onDeleteCompetition(element: Competition) {
    this.deleteById({ id: element.id, route: 'competition' });
  }

}
