

import {Component, Vue, Watch} from "vue-property-decorator";
import Competition from "@/_models/Competition";
import Team from "@/_models/Team";
import {namespace} from "vuex-class";
import TableAdmin from "@/components/TableAdmin.vue";

const teamModule = namespace('TeamModule');
@Component({
  components: {TableAdmin}
})
export default class AdminTeamView extends Vue {

  public teamRequest: Team = new Team();
  public dialogCreate = false;
  public teamsLocal: Team[] = [];
  public columns: string[] = ['id', 'name', 'flag'];

  @teamModule.Action('retrieveAll')
  public retrieveTeams!: (route: string) => void;

  @teamModule.Action
  public create!: (params: { element: Team, route: string }) => Team;

  @teamModule.Getter('getElements')
  public getTeams!: Team[];

  @teamModule.Action
  public deleteById!: (params: { id: string, route: string }) => void;

  mounted(): void {
    this.retrieveTeams('team');
  }

  @Watch('getTeams')
  watchCompetitions(): void {
    this.teamsLocal = this.getTeams;
  }

  public onCreateTeam() {
    this.create({element: this.teamRequest, route: 'team'});
    this.dialogCreate = false;
  }

  public onDeleteTeam(element: Team) {
    this.deleteById({id: element.id, route: 'team'});
  }

}
