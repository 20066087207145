

import {Component, Prop, Vue} from "vue-property-decorator";
import MobileMixin from "@/_mixins/MobileMixin";

@Component
export default class Podium extends MobileMixin{

  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: String, default: '' }) subtitle!: string;
  @Prop({ type: String, default: '' }) innerText!: string;
  @Prop({ type: Number, default: 100 }) height!: number;
  @Prop({ type: String, default: 'warn' }) color!: string;

}
