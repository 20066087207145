

import {Component, Vue} from "vue-property-decorator";
import Competition from "@/_models/Competition";
import {namespace} from "vuex-class";
import MobileMixin from "@/_mixins/MobileMixin";

const competitionModule = namespace('CompetitionModule');

@Component
export default class HouseView extends MobileMixin {

  public alertModel = true;

  @competitionModule.Action('retrieveAll')
  public retrieveAll!: (route: string) => void;

  @competitionModule.Getter('getElements')
  public getCompetitions!: Competition[];

  mounted(): void {
    this.retrieveAll('competition');
  }

  redirectCompetition(idCompetition: string): void {
    this.$router.push(`/home/competition/${idCompetition}/matchs`);
  }

}

