

import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import Match from "@/_models/Match";
import Bet, {BetStatus} from "@/_models/Bet";
import BetService from "@/_services/bet.service";
import Dayjs from "dayjs";
import dayjs from "dayjs";
import BetUtils from "@/_utils/bet.utils";

@Component
export default class BetAlert extends Vue {

  public betHomeLocal = '';
  public betAwayLocal = '';

  @Prop({ type: Object }) match!: Match;
  @Prop({ type: Boolean, default: false }) showReact!: boolean;

  mounted(): void {
    this.betHomeLocal = this.match.myBet.homeScore;
    this.betAwayLocal = this.match.myBet.awayScore;
  }

  get getColor(): string {
    return BetUtils.getColorByBet(this.match.myBet.status);
  }

  get getStatusText(): string {
    return BetUtils.getTextByBet(this.match.myBet.status);
  }

  get getDisable(): boolean {
    const date = Dayjs(this.match.date);
    return dayjs().isAfter(date) || this.match.finished;
  }

  get hasChange(): boolean {
    return ((this.betHomeLocal !== this.match.myBet.homeScore) || (this.betAwayLocal !== this.match.myBet.awayScore)) && !!this.match.myBet?.homeScore && !!this.match.myBet?.awayScore && !this.getDisable;
  }

  @Emit()
  async createBet(): Promise<Bet | void> {
    const betSaved: Bet = await BetService.createBetForMatch(this.match.id, this.match.myBet);
    const alreadyBet = !!this.match.myBet?.user;
    if (betSaved) {
      this.match.myBet = betSaved;
      this.betHomeLocal = this.match.myBet.homeScore;
      this.betAwayLocal = this.match.myBet.awayScore;
    }
    if (!alreadyBet) {
      return betSaved;
    }
  }

  controlNumeric(v: any): void {
    const val = v.target.value.replace(/[^0-9]/g, "");
    this.match.myBet.homeScore = val;
  }

  controlNumericAway(v: any): void {
    const val = v.target.value.replace(/[^0-9]/g, "");
    this.match.myBet.awayScore = val;
  }

}
