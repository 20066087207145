

import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class BadgeStatusBet extends Vue {

  @Prop({ type: String, default: ''}) color!: string;

}
