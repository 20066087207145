import { render, staticRenderFns } from "./LogoInfo.vue?vue&type=template&id=0e844e67&scoped=true"
import script from "./LogoInfo.vue?vue&type=script&lang=ts"
export * from "./LogoInfo.vue?vue&type=script&lang=ts"
import style0 from "./LogoInfo.vue?vue&type=style&index=0&id=0e844e67&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e844e67",
  null
  
)

export default component.exports