

import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class LogoInfo extends Vue {

  @Prop({ type: String, default: '' }) url!: string;
  @Prop({ type: String, default: '' }) alt!: string;
  @Prop({ type: String, default: '' }) info!: string;
  @Prop({ type: String, default: '' }) flag!: string;

}
