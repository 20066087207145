import { render, staticRenderFns } from "./MatchCompetitionView.vue?vue&type=template&id=9bd9e242&scoped=true"
import script from "./MatchCompetitionView.vue?vue&type=script&lang=ts"
export * from "./MatchCompetitionView.vue?vue&type=script&lang=ts"
import style0 from "./MatchCompetitionView.vue?vue&type=style&index=0&id=9bd9e242&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bd9e242",
  null
  
)

export default component.exports