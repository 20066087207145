

import {Component, Vue, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import Match, {MatchsByDay, MatchsCompetitionResponseDto} from "@/_models/Match";
import LogoInfo from "@/components/LogoInfo.vue";
import CardMatchBet from "@/components/CardMatchBet.vue";
import Timeline from "@/components/Timeline.vue";
import Competition from "@/_models/Competition";
import JwtResponse from "@/_models/_response/JwtResponse";
import Bet from "@/_models/Bet";
import dayjs from "dayjs";
import MobileMixin from "@/_mixins/MobileMixin";

const matchModule = namespace('MatchModule');
const competitionModule = namespace('CompetitionModule');
const authModule = namespace('AuthModule');

@Component({
  components: {Timeline, CardMatchBet, LogoInfo}
})
export default class MatchCompetitionView extends MobileMixin {

  public matchs: MatchsCompetitionResponseDto = new MatchsCompetitionResponseDto();
  public dialogSummary = false;
  public loading: any;
  public series: number[] = [];
  public chartOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['Super victoire', 'Victoire', 'Perdu'],
    colors: ['#46C93A', '#FFBA00', '#FF4757'],
    stroke:{
      colors:['#000']
    },
    legend: {
      position: 'bottom'
    },
    tooltip: {
      enabled: false
    },
    dataLabels: {
      enabled: false
    }
  };

  @authModule.Getter
  public getCurrentUser!: JwtResponse;

  @competitionModule.Getter
  public getCurrentCompetition!: Competition;

  @matchModule.Action
  public retrieveAllByCompetition!: (idCompetition: string) => Promise<MatchsCompetitionResponseDto>;

  @matchModule.Getter
  public getLoader!: boolean;

  async mounted(): Promise<void> {
    this.matchs = await this.retrieveAllByCompetition(this.$route.params.id);
    this.series.push(this.matchs.globalStats.percentageSuperWin, this.matchs.globalStats.percentageWin, this.matchs.globalStats.percentageLost);
  }

  @Watch('getLoader')
  watchLoader() {

    if (this.getLoader) {
      this.loading = this.$vs.loading({
        type: 'circles',
        background: '#000000'
      })
    } else {
      this.loading.close();
    }
  }

  scrollToNow() {
    console.log(this.$refs);
    const now = dayjs().format('YYYY-MM-DD');
    console.log(now);
    const element: any = this.$refs[now];
    console.log(element[0]);
    if (element[0]) {
      window.scrollTo({left: 0, top: element[0].offsetTop, behavior: 'smooth'});
    }
  }

  createBet(bet: Bet): void {
    console.log(bet);
    if (bet.user) {
      this.matchs.bets.push(bet);
      this.matchs.numberBets++;
      this.matchs.numberBetsPending++;
    }
  }

  beforeDestroy() {
    this.loading.close();
  }

}
