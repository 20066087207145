

import {Component, Vue, Watch} from "vue-property-decorator";
import MatchCategory from "@/_models/MatchCategory";
import Competition from "@/_models/Competition";
import {namespace} from "vuex-class";
import Match from "@/_models/Match";
import TableAdmin from "@/components/TableAdmin.vue";
import Select from "@/components/Select.vue";
import Datepicker from "@/components/Datepicker.vue";

const matchModule = namespace('MatchModule');
const competitionModule = namespace('CompetitionModule');
const matchCategoryModule = namespace('MatchCategoryModule');
const teamModule = namespace('TeamModule');

@Component({
  components: {Datepicker, Select, TableAdmin}
})
export default class AdminMatchView extends Vue {
  public matchRequest: Match = new Match();
  public dialogCreate = false;
  public matchsLocal: Match[] = [];
  public columns: string[] = ['competition.name', 'homeTeam.name', 'awayTeam.name', 'homeScore', 'awayScore', 'date', 'finished'];

  @matchCategoryModule.Action('retrieveAll')
  public retrieveAllCategories!: (route: string) => void;

  @competitionModule.Action('retrieveAll')
  public retrieveAllCompetitions!: (route: string) => void;

  @teamModule.Action('retrieveAll')
  public retrieveAllTeams!: (route: string) => void;

  @matchModule.Action('retrieveAll')
  public retrieveAllMatchs!: (route: string) => void;

  @matchModule.Action
  public create!: (params: { element: Match, route: string }) => Match;

  @matchModule.Getter('getElements')
  public getMatchs!: Match[];

  @matchCategoryModule.Getter('getElements')
  public getCategories!: MatchCategory[];

  @competitionModule.Getter('getElements')
  public getCompetitions!: Competition[];

  @teamModule.Getter('getElements')
  public getTeams!: Competition[];

  @matchCategoryModule.Action
  public deleteById!: (params: { id: string, route: string }) => void;

  mounted(): void {
    this.retrieveAllMatchs('match');
    this.retrieveAllCategories('matchCategory');
    this.retrieveAllCompetitions('competition');
    this.retrieveAllTeams('team');
  }

  @Watch('getMatchs')
  watchCompetitions(): void {
    this.matchsLocal = this.getMatchs;
  }

  public onEdit(match: Match): void {
    this.matchRequest = match;
    this.dialogCreate = true;
  }

  public openCreateDialog(): void {
    this.matchRequest = new Match();
    this.dialogCreate = true;
  }

  public onCreate() {
    this.create({ element: this.matchRequest, route: 'match' });
    this.dialogCreate = false;
  }

  public onDelete(element: Match) {
    this.deleteById({ id: element.id, route: 'match' });
  }
}
