
import { Component, Vue } from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue';
import Sidebar from "@/components/Sidebar.vue";
import MobileMixin from "@/_mixins/MobileMixin";

@Component({
  components: {
    Sidebar,
    HelloWorld,
  },
})
export default class HomeView extends MobileMixin {

  public activeSidebar = true;

}
