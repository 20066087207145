

import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class ButtonReact extends Vue {

  @Prop({ type: Boolean, default: false }) active!: boolean;
  @Prop({ type: String, default: '' }) label!: string;

}
